import React from 'react'
import { Link } from 'react-router-dom'

const LetsMeet = () => {
  return (
    <div className='d-flex flex-column justify-content-center align-items-center mt-5 text-white' style={{ backgroundColor: "rgb(0, 0, 0,0.5)", padding: "60px", height: 350 }}>
      <p className='mb-2 fw-bold w-100 text-center' style={{ fontSize: "32px" }}>Let's build something amazing together!</p>
      <div style={{ maxWidth: "400px", textAlign: "center" }}>
        <p className='text-center mb-4'>I'm eager to hear about your challenges and develop innovative solutions</p>
        <Link to="/meeting" target='_blank'>
          <button className='py-2 px-4 fw-bold mt-2' style={{ backgroundColor: 'var(--yellow)', color: 'var(--dark)', borderRadius: '40px' }}>Let&apos;s Meet</button>
        </Link>
      </div>
    </div>
  )
}

export default LetsMeet
