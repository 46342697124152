import React, { useLayoutEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../components/Footer';
import LetsMeet from '../components/LetsMeet';
import { app, web } from '../Json/web';

const ProjectDetail = () => {
  const { id } = useParams();

  useLayoutEffect(() => {
    //@ts-ignore
    window.scrollTo(0, document.getElementById("scroll_Top"))
  }, [])

  const data = useMemo(() => {
    const project = web.find((project) => project.id === id) ?? app.find((project) => project.id === id)
    return project
  }, [id])

  if (!data) return (
    <div id='scroll_Top' style={{ minHeight: '100vh', width: '100%', height: '100%' }}>
      <div className='container' style={{ paddingTop: "100px" }}>
        <div className='text-white'>Project not found</div>
      </div>
    </div>
  )

  return (
    <div id='scroll_Top' style={{ minHeight: '100vh', width: '100%', height: '100%' }}>
      <div className='container' style={{ paddingTop: "100px" }}>
        <div className='text-center mb-5'>
          <h1 className='text-white fs-1'>{data.name}</h1>
          {!!data.image && (
            <img {...data.image} style={{ maxWidth: 600, maxHeight: 400 }} className='mb-4' />
          )}
        </div>
        {!!data.description && (
          <div className='text-white mb-4'>
            <h2 className='text-start mb-2' style={{ fontWeight: "600", fontSize: "24px" }}>About Project</h2>
            <p>{data.description}</p>
          </div>
        )}
        {!!data.features.length && (
          <div className='text-white mb-4'>
            <h2 className='text-start mb-2' style={{ fontWeight: "600", fontSize: "24px" }}>Key Features</h2>
            <ul style={{ listStyle: "outside", paddingLeft: "20px" }}>
              {data.features.map((feature) => (
                <li>{feature.description}</li>
              ))}
            </ul>
          </div>
        )}
        {!!data.tools?.length && (
          <div className='text-white mb-4'>
            <h2 className='text-start mb-2' style={{ fontWeight: "600", fontSize: "24px" }} >Technologies used</h2>
            <div className='d-flex flex-wrap gap-4'>
              {data.tools.map((tool) => (
                <button className='p-1 px-3' style={{ backgroundColor: 'var(--yellow)', color: 'var(--dark)', borderRadius: '40px' }}>{tool}</button>
              ))}
            </div>
          </div>
        )}
        {!!data?.screen?.length && (
          <div className='text-white mb-4'>
            <h2 className='text-start mb-2' style={{ fontWeight: "600", fontSize: "24px" }} >Gallery</h2>
            <div className='row row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-1 g-3 mt-2 mb-3'>
              {data?.screen.map((image: any) => (
                  <div className='col' style={{ objectFit: "cover", objectPosition: "center" }}>
                    <img src={image} alt='gallery-image' className='w-100 h-100' />
                  </div>
                ))
              }
            </div>
          </div>
        )}
      </div>
      <LetsMeet />
      <Footer />
    </div>
  )
}

export default ProjectDetail
