import React from 'react'
import { App, Web } from '../Json/web'

interface Props {
  project: Web | App
  viewMore(): void
}

const ProjectCard = ({ project, viewMore }: Props) => {
  return (
    <div className='project-web overflow-hidden' style={{ backgroundColor: 'white', borderRadius: '5px' }}>
      <div className='d-flex gap-1 justify-content-end align-items-center w-100 text-center pe-2 bg-white' style={{ zIndex: 11, height: '22px' }}>
        <div style={{ backgroundColor: '#6eb34a', width: '6px', height: '6px', borderRadius: '100%' }}></div>
        <div style={{ backgroundColor: '#dfac53', width: '6px', height: '6px', borderRadius: '100%' }}></div>
        <div style={{ backgroundColor: '#d7665d', width: '6px', height: '6px', borderRadius: '100%' }}></div>
      </div>
      <div className={`${project.className ?? ''} project-image position-relative image w-100`} style={{ backgroundColor: '#f9f9f9' }}>
        {project.image ? (
          <img {...project.image} className={`${project.image.className} w-100 h-100`} />
        ) : (
          <div className='d-flex justify-content-center align-items-center w-100 h-100 fs-1'>
            {project.name.split(' ').map((n) => n.charAt(0).toUpperCase()).join('')}
          </div>
        )}
        <div className='card-projects position-absolute bottom-0 top-0 start-0 end-0 w-100 d-flex justify-content-center align-items-center' style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
          {Boolean(screen) && (
            <button className='btn-project' onClick={viewMore}>View More</button>
          )}
        </div>
      </div>
      <div className='w-100 text-center project-name' style={{ zIndex: 11 }}>
        <p className='mb-0 text-nowrap' style={{ textTransform: 'uppercase', overflow: 'hidden', textOverflow: 'ellipsis' }}>{project.name}</p>
      </div>
    </div>
  )
}

export default ProjectCard