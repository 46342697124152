import React from 'react'
import MyProjects from '../components/MyProjects'
import Footer from '../components/Footer'

const Portfolio = () => {
  return (
    <>
      <div className='pb-5' style={{ paddingTop: "100px" }}>
        <MyProjects />
      </div>
      <Footer />
    </>
  )
}

export default Portfolio
