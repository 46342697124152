// Tech Stack
import ReactSvg from '../assets/images/tech/react.svg'
import Typescript from '../assets/images/tech/typescript.svg'
import Sql from '../assets/images/tech/sql.svg'
import Node from '../assets/images/tech/node.svg'
import Next from '../assets/images/tech/next.svg'
import Redux from '../assets/images/tech/redux.svg'
import php from '../assets/images/tech/php.svg'
import Laravel from '../assets/images/tech/laravel.svg'
import Bootstrap from '../assets/images/tech/bootstrap.svg'
import Stripe from '../assets/images/tech/stripe.svg'
import Algolia from '../assets/images/tech/algolia.svg'
import Git from '../assets/images/tech/git.svg'

// #region Web Projects
import smeisty from '../assets/images/projects/web/smeisty.png'
import offTheChain from '../assets/images/projects/web/off-the-chain.png'
import nerko from '../assets/images/projects/web/nerko-1.png'
import chatalyst from '../assets/images/projects/web/chatalyst.png'
import justconnect from '../assets/images/projects/web/justconnect.png'
import michaeljhonstone from '../assets/images/projects/web/michaeljhonstone.png'
import whatshesaid from '../assets/images/projects/web/whatshesaid.png'
import woodawaste from '../assets/images/projects/web/woodawaste.png'
import msbooks from '../assets/images/projects/web/msbooks.png'
import cyron from '../assets/images/projects/web/cyron-1.png'
import storeAndShare from '../assets/images/projects/web/store-and-share.png'
import travelostic from '../assets/images/projects/web/travelostic.jpg'
import cartisan from '../assets/images/projects/web/cartisan.png'
import aljazeeraTravels from '../assets/images/projects/web/aljazeera-travel.jpg'
import acadeGram from '../assets/images/projects/web/acade-gram.jpg'
import assurex from '../assets/images/projects/web/assurex.jpg'
import mrBookWeb from '../assets/images/projects/web/mr-book-web.jpg'
import templeOfFit from '../assets/images/projects/web/temple-of-fit.png'
import NerkoMockup from '../assets/nerkomockup (1).jpg'
import NerkoMockup2 from '../assets/nerkomockup (2).jpg'
import CyronMockup1 from '../assets/cyrokmock (1).jpg'
import CyronMockup2 from '../assets/cyrokmock (2).jpg'
import CyronMockup from '../assets/flat-devices-mockup (3).png'
import smeistyMockup from '../assets/smeistymockup (1).jpg'
import smeistyMockup2 from '../assets/smeistymockup (2).jpg'
import smeistyMockup3 from '../assets/smeistymockup (3).jpg'
import CartisanMockup from '../assets/cartisanmockup.png'
import CartisanMockup1 from '../assets/cartisanmockup1__3_-removebg-preview.png'
import CartisanMockup2 from '../assets/cartisanmockup1__2_-removebg-preview.png'
import CartisanMockup3 from '../assets/cartisanmockup1__1_-removebg-preview.png'
import offTheChainMockUpMockUp from '../assets/advofairmockup (1).jpg'
import offTheChainMockUpMockUp2 from '../assets/advofairmockup (2).jpg'
import offTheChainMockUpMockUp3 from '../assets/advofairmockup__3_-removebg-preview.png'
import pandsmockup from '../assets/pandsmockup (3).jpg'
import pandsmockup2 from '../assets/pandsmockup (4).jpg'
// #endregion

// #region App Projects
import montage from '../assets/images/projects/app/montage.png'
import montage1 from '../assets/montage-1.png'
import montage2 from '../assets/montage-2.png'
import montage3 from '../assets/montage-3.png'
import montage4 from '../assets/montage-4.png'
import flex from '../assets/images/projects/app/flex.png'
import blukyte from '../assets/images/projects/app/blukyte.png'
import blukyte1 from '../assets/blukyte-1.png'
import blukyte2 from '../assets/blukyte-2.png'
import blukyte3 from '../assets/blukyte-3.png'
import klimb from '../assets/images/projects/app/klimb.png'
import MSBookMobile from '../assets/images/projects/app/ms-book-mobile.jpg'
import hopeUp from '../assets/images/projects/app/hopeup.jpg'
import myPetBuddy from '../assets/images/projects/app/my-pet-buddy.png'
import filmStack from '../assets/images/projects/app/film-stack.png'
import streetFood from '../assets/images/projects/app/street-food.png'
import recipeCup from '../assets/images/projects/app/recipe-cup.png'
import zetraAutomation from '../assets/images/projects/app/zetra-automation.png'
import Iviou from '../assets/images/projects/app/Iviou.png'
import veriMe from '../assets/images/projects/app/veri-me.png'
import tuAyuda from '../assets/images/projects/app/tu-ayuda.png'
import getSafeNow from '../assets/images/projects/app/get-safe-now.png'
import legaChat from '../assets/images/projects/app/lega-chat.png'
import tactiver from '../assets/images/projects/app/tactiver.png'
import masmasFit from '../assets/masmasfit.png'
import imageSticker from '../assets/Imagesticker.png'
import templeoffit from '../assets/templeoffit.png'
// #endregion

//Services
// import webDevelopment from '../assets/web-development.jpg'
import webDevelopment from '../assets/web-development.png'
import appDevelopment from '../assets/app-development.png'
import uiuxDesign from '../assets/uiux-design.png'

// #region Client reviews
import Picture1 from '../assets/images/clients/pic-1.png'
import Picture2 from '../assets/images/clients/pic-2.png'
import Picture3 from '../assets/images/clients/pic-3.png'
import Picture4 from '../assets/images/clients/pic-4.png'
import Picture5 from '../assets/images/clients/pic-5.png'
import Picture6 from '../assets/images/clients/pic-6.jpg'
import Picture7 from '../assets/images/clients/product_img4.jpg'
import Picture8 from '../assets/images/clients/product_img6.jpg'
import Picture9 from '../assets/images/clients/product_img8.jpg'
import Picture10 from '../assets/images/clients/product_img10.jpg'
import VeriMe1 from '../assets/verimemockup (1).jpg'
import VeriMe2 from '../assets/verimemockup (2).jpg'
import TempleOfFit2 from '../assets/templeoffitmockup (3).jpg'
import TempleOfFit4 from '../assets/templeoffitmockup (4).jpg'
import Tactiver1 from '../assets/tactiketmockup (2).jpg'
import Tactiver2 from '../assets/tactiketmockup (1).jpg'
import lviou1 from '../assets/ivonmockup.jpg'
import lviou2 from '../assets/ivonmockup (2).jpg'
import Yest from '../assets/yest.png'
import Yest1 from '../assets/yestmockup.jpg'
import Yest2 from '../assets/yestmockup (2).jpg'
import Zetra1 from '../assets/zetraautomationmockup (1).jpg'
import Zetra2 from '../assets/zetraautomationmockup (2).jpg'
import MyPetBuddy1 from '../assets/mypetbuddymockup.jpg'
import MyPetBuddy2 from '../assets/mypetbuddymockup (2).jpg'
import Street1 from '../assets/streetfoodmockup (1).jpg'
import Street2 from '../assets/streetfoodmockup (2).jpg'
import Film1 from '../assets/filmstackmockup (1).jpg'
import Film2 from '../assets/filmstackmockup (2).jpg'
import Rescipe1 from '../assets/recipecupmockup (1).jpg'
import Rescipe2 from '../assets/recipecupmockup (2).jpg'
import Rescipe3 from '../assets/recipecupmockup (3).jpg'
import Rescipe4 from '../assets/recipecupmockup (4).jpg'
import Rescipe5 from '../assets/recipecupmockup (5).jpg'
import Rescipe7 from '../assets/rescipe-cup-user7.png'
import Tuayada1 from '../assets/toayadamockup (2).jpg'
import Tuayada2 from '../assets/toayadamockup.jpg'
import Mesmesfit1 from '../assets/masmasfitmockup (2).jpg'
import Mesmesfit2 from '../assets/masmasfitmockup.jpg'
import Offthechain1 from '../assets/off-the-chain1.png'
import Offthechain2 from '../assets/off-the-chain2.png'
import Offthechain3 from '../assets/off-the-chain3.png'
import Smeisty1 from '../assets/smeisty (1).png'
import Smeisty2 from '../assets/smeisty (2).png'
import Smeisty4 from '../assets/smeisty (4).png'
import Smeisty5 from '../assets/smeisty (5).png'
import Cartisan1 from '../assets/cartisan (1).png'
import Cartisan2 from '../assets/cartisan (2).png'
import Cartisan3 from '../assets/cartisan (3).png'
import PandS2 from '../assets/pands (2).png'
import Cyron1 from '../assets/cyron (1).png'
import Cyron2 from '../assets/cyron (2).png'
import Nerko1 from '../assets/nerko (2).png'
import Nerko2 from '../assets/nerko (1).png'
import Flex1 from '../assets/flexmockup (2).jpg'
import Flex2 from '../assets/flexmockup.jpg'
import Imagesticker1 from '../assets/imageStickermockup.jpg'
import Imagesticker2 from '../assets/imageStickermockup.jpg'
// #endregion


// #region industries
import Fitness from '../assets/images/industries/fitness.jpg'
import Healthcare from '../assets/images/industries/healthcare.jpg'
import Ecom from '../assets/images/industries/ecom.jpg'
import Travel from '../assets/images/industries/travel.jpg'
import Blockchain from '../assets/images/industries/blockchain.jpg'
import Nfts from '../assets/images/industries/nfts.jpg'
import Cyrol from '../assets/images/industries/cyrol1.png'
import CartisanWeb from '../assets/images/industries/aboutus.jpg'
import NerkoNew from '../assets/images/industries/nerko1.png'
import Masmas from '../assets/images/industries/masmas.png'
import Smeisty from '../assets/images/industries/smeisty.png'
import Travelastic from '../assets/images/industries/travel2.png'
// #endregion

// #region projects mobile version
import SmeistyMobile from '../assets/smeistymobile.jpg'
import nerkoMobile from '../assets/nerkomobile.jpg'
import cyronMobile from '../assets/cyronmobile.jpg'
import ofthechaninMobile from '../assets/offthechainmobile.jpg'
import pandsMobile from '../assets/pandsmobile.jpg'
import cartisanMobile from '../assets/cartisanmobile.jpg'
import { ImgHTMLAttributes } from 'react'
// #endregion

interface Feature {
  name: string,
  description: string
}
export interface Web {
  id: string
  name: string
  tagLine?: string
  description?: string
  image: ImgHTMLAttributes<HTMLImageElement> | null
  url: string
  screen?: any[]
  features: Feature[]
  position?: 'top' | 'center' | 'bottom'
  mobile_screen?: any[]
  tools?: string[]
  className?: string
}
export interface App {
  id: string
  name: string
  tagLine?: string
  description?: string
  image: ImgHTMLAttributes<HTMLImageElement> | null
  url: string
  screen?: any[]
  features: Feature[]
  tools?: string[]
  className?: string
}
interface Service {
  title: string,
  description: string,
  image: {
    bgColor: string,
    src: any,
    alt: string,
  },
}

interface Details {
  name: string,
  description?: string,
  image: any,
  url: string,
  screen?: any[],
  features: string[],
  position?: 'top' | 'center' | 'bottom',
  "order"?: boolean

}

interface Industries {
  title: string,
  description: string,
  industryDetail?: string,
  route?: string
  image: {
    src: any,
    alt: string,
  },
  website?: Details[]
}

export const techStack = [
  {
    url: 'https://reactjs.org/',
    logo: ReactSvg,
  },
  {
    url: 'https://www.typescriptlang.org/',
    logo: Typescript,
  },
  {
    url: 'https://www.mysql.com/',
    logo: Sql,
  },
  {
    url: 'https://nodejs.org/en/',
    logo: Node,
  },
  {
    url: 'https://nextjs.org/',
    logo: Next,
  },
  {
    url: 'https://redux-toolkit.js.org/',
    logo: Redux,
  },
  {
    url: 'https://www.php.net/',
    logo: php,
  },
  {
    url: 'https://laravel.com/',
    logo: Laravel,
  },
  {
    url: 'https://getbootstrap.com/',
    logo: Bootstrap,
  },
  {
    url: 'https://stripe.com/docs',
    logo: Stripe,
  },
  {
    url: 'https://www.algolia.com/',
    logo: Algolia,
  },
  {
    url: 'https://about.gitlab.com/',
    logo: Git,
  },
]

export const web: Web[] = [
  // Chatalyst
  {
    id: "f72cfc38-1f33-4a97-bf55-526f2fd1e422",
    name: "Chatalyst",
    tagLine: "Revolutionizing job screening with AI and SMS",
    description: "Chatalyst is a web application that revolutionizes job screening for recruiters by leveraging the power of ChatGPT and SMS. It streamlines the initial screening process, saving time and resources",
    image: {
      src: chatalyst,
      alt: "Chatalyst",
      className: 'object-top object-cover'
    },
    url: "",
    features: [
      {
        name: "AI-powered SMS screening",
        description: "Ask tailored questions via SMS and receive interpreted answers using advanced NLP"
      },
      {
        name: "Intelligent follow-ups",
        description: "Clarify ambiguous responses with smart rephrasing, ensuring comprehensive information"
      },
      {
        name: "Effortless workflow",
        description: "Add job positions, input questions, and let Chatalyst handle the initial screening"
      },
      {
        name: "Reduced overhead costs",
        description: "Save time and resources by automating the initial screening phase"
      }
    ],
    screen: [],
    mobile_screen: [],
    position: "top",
    tools: ['Next JS', 'Material UI', 'Node JS', 'ChatGPT integration', 'Vonage']
  },
  // JustConnect
  {
    id: "1d867d15-5dd2-4817-bd1e-366d7e92d9e8",
    name: "JustConnect",
    tagLine: "Connecting crafting enthusiasts with resources",
    description: "JustConnect is a web application that seamlessly connects crafting enthusiasts with the perfect resources to fuel their creativity. Whether you're a seasoned crafter or just starting out, JustConnect empowers you to find the right tools, materials, and skilled professionals to bring your crafting vision to life",
    image: {
      src: justconnect,
      alt: "JustConnect",
      className: 'object-top object-cover'
    },
    url: "https://justconnect.dk",
    screen: [],
    features: [
      {
        name: "Discover local crafting resources",
        description: "Find nearby craft stores, workshops, and classes to enhance your skills and knowledge"
      },
      {
        name: "Connect with skilled craftsmen",
        description: "Locate experienced professionals for bespoke projects, repairs, or personalized guidance"
      },
      {
        name: "Explore a diverse crafting community",
        description: "Share your creations, get inspired by others, and learn from fellow enthusiasts"
      },
      {
        name: "Simplify your crafting journey",
        description: "Access valuable resources, tips, and tutorials to elevate your crafting experience"
      }
    ],
    position: "center",
    mobile_screen: [],
    tools: ['Laravel', 'React JS']
  },
  // Showcase yoursef - Michael Jhonstone
  {
    id: "23e1294e-0dd5-4ff2-b1d5-5f9a4f7c5e8f",
    name: "Showcase Yourself",
    tagLine: "Personal Blog & Portfolio in One",
    description: "Personal Blog/Portfolio is a web application that empowers you to create a stunning online presence tailored to your unique needs. Whether you're a blogger, freelancer, or creative professional, this platform provides the tools to showcase your work, share your thoughts, and connect with the world",
    image: {
      src: michaeljhonstone,
      alt: "Showcase Yourself",
      className: 'object-top object-cover'
    },
    url: "",
    screen: [],
    features: [
      {
        name: "Build your portfolio",
        description: "Easily showcase your projects, experiences, and skills with a customizable layout"
      },
      {
        name: "Start your blog",
        description: "Share your ideas, insights, and stories with a built-in blogging platform"
      },
      {
        name: "Connect with your audience",
        description: "Integrate social media links and contact forms to foster engagement"
      },
      {
        name: "Simple and intuitive",
        description: "Enjoy a user-friendly interface for effortless website creation and management"
      }
    ],
    position: "bottom",
    mobile_screen: [],
    tools: [
      "Web application",
      "Content Management System (CMS)"
    ]
  },
  // What She Said
  {
    id: "c6487efb-23af-4c5e-8b7c-65d6ec647f50",
    name: "What She Said",
    tagLine: "Connecting businesses with authentic customer testimonials",
    description: "What She Said is an innovative web application that connects businesses with authentic customer testimonials and shoutouts. It empowers businesses to build trust, attract new customers, and amplify their brand voice",
    image: {
      src: whatshesaid,
      alt: "What She Said",
      className: 'object-top object-cover'
    },
    url: "",
    screen: [],
    features: [
      {
        name: "List your business",
        description: "Easily create a profile highlighting your offerings, location, and contact information"
      },
      {
        name: "Encourage shoutouts",
        description: "Prompt customers to share their positive experiences and recommendations"
      },
      {
        name: "Showcase feedback",
        description: "Display genuine customer testimonials to build trust and credibility"
      },
      {
        name: "Gain valuable insights",
        description: "Analyze shoutouts to understand customer sentiment and improve your services"
      }
    ],
    position: "top",
    mobile_screen: [],
    tools: [
      "Web application",
      "User review management system"
    ]
  },
  // Wood A Wsate
  {
    id: "9b7fb22e-6679-4c2e-8a61-ff6ad7e2d27f",
    name: "WoodAWaste",
    tagLine: "Crafting Beauty from Reclaimed Wood",
    description: "WoodAWaste is more than just a furniture company; it's a sustainable movement transforming waste wood into unique and stunning plank tables. We are driven by a passion for environmental responsibility and creating pieces that embrace raw beauty and functionality",
    image: {
      src: woodawaste,
      alt: "WoodAWaste",
      className: 'object-top object-cover'
    },
    url: "https://woodawaste.dk/",
    screen: [],
    features: [
      {
        name: "Sustainability",
        description: "We prioritize recycling waste wood, reducing our environmental footprint and giving neglected materials a second life"
      },
      {
        name: "Uniqueness",
        description: "Each plank table is one-of-a-kind, showcasing the natural textures, patterns, and veins of reclaimed wood"
      },
      {
        name: "Craftsmanship",
        description: "We combine traditional techniques with modern design principles to create functional and durable furniture"
      },
      {
        name: "Timeless Design",
        description: "Our tables are built to last, offering a touch of rustic charm to any space"
      }
    ],
    position: "center",
    mobile_screen: [],
    tools: [
      "Web application",
      "User review management system"
    ]
  },
  // Smeisty
  {
    id: "c0ed3884-535a-4fbb-85f7-00d5e45bdc6b",
    name: "Smeisty.App",
    tagLine: "All-in-One NFT Community Management Platform",
    description: "Smeisty.App is the 100% digital solution that empowers companies to seamlessly manage and nurture their NFT communities. We support you throughout your entire NFT journey, from strategic planning and launch to ongoing engagement and community building",
    image: {
      src: smeisty,
      alt: "Smeisty.App",
      className: 'object-top object-cover'
    },
    url: "https://smeisty.app",
    features: [
      {
        name: "Effortless Onboarding",
        description: "Users can log in conveniently using familiar methods like email, Google, Twitter, and MetaMask"
      },
      {
        name: "Intuitive Interface",
        description: "Enjoy a user-friendly design that makes Smeisty.App accessible for everyone, regardless of technical expertise"
      },
      {
        name: "Foster Connections",
        description: "Facilitate real-time interactions with built-in chat functionalities, fostering a vibrant community atmosphere"
      },
      {
        name: "Secure Transactions",
        description: "Enable seamless online payments via MetaMask, ensuring a safe and reliable experience"
      },
      {
        name: "Comprehensive Wallet Management",
        description: "Offer multiple wallet options, including manual addition, MetaMask integration, and cold storage support"
      },
      {
        name: "NFT Visibility",
        description: "Showcase your NFT collection through user-friendly listings, attracting and engaging potential customers"
      },
      {
        name: "Build Your Fren List",
        description: "Encourage community connections by allowing users to follow each other and build their 'Fren' lists"
      }
    ],
    screen: [
      smeistyMockup2,
      smeistyMockup,
      smeistyMockup3,
    ],
    mobile_screen: [
      SmeistyMobile
    ],
    position: "bottom",
    tools: ["React Js", "Redux", "Redux Toolkit Query", "Node Js", "Bootstrap", "MetaMask", "Web3"]
  },
  // Cartisan
  {
    id: "e4dd3838-28f5-4e29-a49f-48a27848b4dr",
    name: "Cartisan",
    tagLine: "Mobile-First Marketplace",
    description: "Cartisan empowers everyone to sell online with ease, regardless of technical expertise. Our mission is to break down barriers and provide accessible e-commerce solutions for everyone",
    image: {
      src: cartisan,
      alt: "Cartisan",
      className: 'object-top object-cover'
    },
    url: "",
    features: [
      {
        name: "Mobile-first approach",
        description: "Create, manage, and run your online store entirely from your smartphone. No computer or coding required"
      },
      {
        name: "Simplified product listing",
        description: "Add products with ease and speed directly through your phone"
      },
      {
        name: "User-friendly interface",
        description: "Our intuitive design makes navigating and managing your store effortless"
      },
      {
        name: "Low barriers to entry",
        description: "Start selling quickly without the need for expensive equipment or technical knowledge"
      }
    ],
    screen: [
      CartisanMockup1,
      CartisanMockup2,
      CartisanMockup3
    ],
    mobile_screen: [
      cartisanMobile
    ],
    position: "bottom",
    tools: ["Next Js", "React Redux", "Redux Toolkit", "Bootstrap", "Firebase", "Algolia", "Swiper"]
  },
  // Store and Share Vault
  {
    id: "4bda34cf-0b81-464b-a22d-88b66d95a01r",
    name: "Store and Share Vault",
    tagLine: "Secure Digital Legacy Partner",
    description: "Store and Share Vault empowers you to securely store and share your valuable data with peace of mind. Powered by Amazon AWS encryption, our application offers a reliable and robust platform to safeguard your digital assets and empower your loved ones",
    image: {
      src: storeAndShare,
      alt: "Store and Share Vault",
      className: 'object-top object-cover'
    },
    url: "https://p-and-s-services-33412-staging.botics.co/",
    features: [
      {
        name: "Online Account Logins",
        description: "Store login credentials for banking, social media, and other online accounts in a single, secure location"
      },
      {
        name: "Important Documents",
        description: "Upload and organize essential documents like passports, insurance policies, and wills"
      },
      {
        name: "Precious Files",
        description: "Securely store photos, videos, and other files of any size, preserving cherished memories"
      }
    ],
    screen: [
      pandsmockup,
      pandsmockup2,
    ],
    mobile_screen: [
      pandsMobile
    ],
    position: "top",
    tools: ["React Js", "Redux Toolkit Query", "Bootstrap", "Stripe"]
  },
  // Off the Chain
  {
    id: "3c5d38fc-2c7b-42c1-93bc-4b9c7155b58r",
    name: "Off the Chain",
    tagLine: "Wear Your NFTs on Your Sleeve (Literally)",
    description: "Off the Chain bridges the gap between the digital NFT world and physical fashion. We offer a seamless experience where you can transform your cherished PFP (profile picture) into a unique, custom-printed t-shirt",
    image: {
      src: offTheChain,
      alt: "Off the Chain",
      className: 'object-top object-cover'
    },
    url: "",
    features: [
      {
        name: "Connect your wallet",
        description: "Securely link your MetaMask wallet to the platform"
      },
      {
        name: "Choose your PFP",
        description: "Select the NFT avatar you want to showcase from your collection"
      },
      {
        name: "Pay with ease",
        description: "Simply pay 0.08 ETH using your connected wallet"
      },
      {
        name: "Receive your custom creation",
        description: "We'll print your chosen PFP on a premium t-shirt and ship it directly to you, along with a bonus custom tree planted in your name"
      }
    ],
    screen: [
      offTheChainMockUpMockUp,
      offTheChainMockUpMockUp2,
      offTheChainMockUpMockUp3,
    ],
    mobile_screen: [
      ofthechaninMobile
    ],
    position: "center",
    tools: ["React Js", "Bootstrap", "NFTs", "MetaMask", "Node Js", "Web 3"]
  },
  // Cyron
  {
    id: "d64f3542-14a3-4c8e-b22d-ecdc434bb2dr",
    name: "Cyron",
    tagLine: '',
    description: "",
    image: {
      src: cyron,
      alt: "Cyron",
      className: 'object-top object-cover'
    },
    url: "https://cyron.demo1.zsoft.link/",
    features: [],
    screen: [
      CyronMockup1,
      CyronMockup2,
    ],
    mobile_screen: [
      cyronMobile
    ],
    tools: ["React JS", "Bootstrap", "Swiper"],
    position: "top"
  },
  // Nerko
  {
    id: "7e4d08d3-9db1-4934-89c1-8a88840fbc7r",
    name: "Nerko",
    tagLine: '',
    description: '',
    image: {
      src: nerko,
      alt: "Nerko",
      className: 'object-top object-cover'
    },
    url: "https://nerko.demo1.zsoft.link/",
    features: [],
    screen: [
      NerkoMockup,
      NerkoMockup2
    ],
    mobile_screen: [
      nerkoMobile
    ],
    tools: ["React Js", "Bootstrap", 'Swiper'],
    position: "top"
  },
  // Al Jazeera Travel
  {
    id: "89a62fb0-4cb2-41b5-bc44-34b5896b480r",
    name: "Al Jazeera Travel",
    tagLine: "One-Stop Travel Companion",
    description: "Al Jazeera Travel is your trusted partner for seamless and unforgettable travel experiences, serving the Qatar market for over 14 years. We offer a comprehensive suite of services designed to meet your every travel need, from planning to execution",
    image: {
      src: aljazeeraTravels,
      alt: "Al Jazeera Travel",
      className: 'object-top object-cover'
    },
    url: "https://www.aljazeratravel.com/",
    features: [
      {
        name: "Flight Reservations",
        description: "Book domestic and international flights with ease, choosing from various airlines and routes"
      },
      {
        name: "Hotel Bookings",
        description: "Find the perfect accommodation for your trip, from budget-friendly options to luxurious hotels"
      },
      {
        name: "Holiday Packages",
        description: "Explore curated vacation packages that combine flights, hotels, and activities, taking the hassle out of planning"
      },
      {
        name: "Cruise Bookings",
        description: "Embark on an unforgettable journey at sea with our selection of exciting cruise options"
      },
      {
        name: "Visa Arrangements",
        description: "Navigate the visa application process efficiently with our expert assistance"
      },
      {
        name: "Travel Insurance",
        description: "Secure peace of mind with comprehensive travel insurance plans for unexpected events"
      }
    ],
    screen: [],
    mobile_screen: [],
    tools: ["React Js", "PHP"],
    position: "top",
  },
  // MS Books
  {
    id: "91e7d8a1-2f5a-4ab0-b300-9d41457eb2c4",
    name: "MS Books",
    tagLine: "Achieve A*-level success with trusted resources",
    description: "MS Books is your trusted online resource for achieving academic excellence in O Level, IGCSE, and A Level examinations. We empower students with comprehensive study materials, innovative tools, and expert guidance to help them unlock their full potential and consistently achieve top grades (A*)",
    image: {
      src: msbooks,
      alt: "MS Books",
      className: 'object-top object-cover'
    },
    url: "https://www.msbooks.pk/",
    screen: [],
    features: [
      {
        name: "In-depth Study Guides",
        description: "Gain a clear and thorough understanding of key concepts with our high-quality study guides, crafted by experienced educators"
      },
      {
        name: "Effective Learning Tools",
        description: "Utilize a variety of interactive resources, including practice questions, mock exams, and flashcards, to solidify your knowledge and refine your exam technique"
      },
      {
        name: "Expert Guidance",
        description: "Access valuable tips, strategies, and support from our team of dedicated education professionals"
      },
      {
        name: "Proven Track Record",
        description: "Leverage our proven methods and materials, trusted by countless students who have achieved outstanding results"
      }
    ],
    position: "top",
    mobile_screen: [],
    tools: [
      "Web application",
      "Learning Management System (LMS)"
    ]
  },
  // Travelostic
  {
    id: "f1d5e11a-9a09-4e0b-ba0a-fc1f65a0c06r",
    name: "Travelostic",
    tagLine: '',
    description: "Travelistic is a B2B and B2C online booking system that allows travel businesses to search and book flights, hotels, dynamic packages, car transfers and much more. It offers a variety of features such as multiple sales channels, a call center in 6 languages, and a comprehensive reporting module.",
    image: {
      src: travelostic,
      alt: "Travelostic",
      className: 'object-top object-cover'
    },
    url: "https://travelostic.com/",
    features: [
      {
        name: "Multiple Sales Channels",
        description: "Sell travel services through various channels including B2B, B2C, B2B2B, and B2C2C."
      },
      {
        name: "Extensive Booking Options",
        description: "Search and book flights (regular and low-cost), hotels (over 771,000 options), visas, and car transfers."
      },
      {
        name: "Competitive Pricing",
        description: "Access a variety of suppliers and GDS integrations (Galileo, Sabre, Sirena, Amadeus, Worldspan) to ensure you can offer the lowest fares."
      },
      {
        name: "Multilingual Support",
        description: "Benefit from a call center with staff speaking 6 languages (English, German, Arabic, Turkish, Spanish, and Kurdish)."
      },
      {
        name: "Comprehensive Booking Management",
        description: "Manage bookings easily with a system that includes booking history, filters, and a complete reporting module."
      },
      {
        name: "Flexible Payment Options",
        description: "Accept payments through various methods including credit cards, debit cards, bank transfers, and deposit lines."
      },
      {
        name: "Advanced Search Functionality",
        description: "Utilize map-based search and star rating filters to find the perfect hotels for your clients."
      },
      {
        name: "API Integrations",
        description: "Integrate Travelistic's functionalities with your existing systems through XML API integrations."
      },
      {
        name: "Detailed Booking History",
        description: "Access a simple and informative list of all your bookings with filters for easy searching and management."
      },
    ],
    position: "top",
    tools: ["React JS", "PHP"],
    screen: [],
    mobile_screen: [],
  },
  // ACADEGRAM
  {
    id: "027a13b4-1012-4a0c-8502-9d0bcb69f9er",
    name: "ACADEGRAM",
    tagLine: '',
    description: '',
    image: {
      src: acadeGram,
      alt: "ACADEGRAM",
      className: 'object-top object-cover'
    },
    url: "",
    features: [],
    screen: [],
  },
  // ASSURE-X
  {
    id: "e1e52d5d-f9c0-4f0e-83e5-0a77a6ef23fr",
    name: "ASSURE-X",
    tagLine: '',
    description: '',
    image: {
      src: assurex,
      alt: "ASSURE-X",
      className: 'object-top object-cover'
    },
    url: "",
    features: [],
    screen: [],
  },
  // ASSURE-X Inner Screen
  {
    id: "3a72d33a-6a44-4a11-b42b-8dc2c5de6dbr",
    name: "ASSURE-X INNER SCREEN",
    tagLine: '',
    description: '',
    image: {
      src: assurex,
      alt: "ASSURE-X INNER SCREEN",
      className: 'object-top object-cover'
    },
    url: "",
    features: [],
    screen: [],
  },
]

export const app: App[] = [
  // Montage
  {
    id: "1f5fc413-7a6b-4f96-89d8-1a55a55e9f16",
    name: "Montage",
    tagLine: "Capture and Share Event Memories Together",
    description: "Montage is a mobile app that lets you capture and share short video clips linked to specific events. It's more than just a social media platform; it's a place to collaboratively tell the story of an event through the eyes of everyone involved",
    image: {
      src: montage,
      alt: 'Montage',
      className: 'object-center object-cover'
    },
    url: "",
    screen: [montage1, montage2, montage3, montage4],
    features: [
      {
        name: "Capture fleeting moments",
        description: "Record short clips with easy-to-use tools"
      },
      {
        name: "Link to events",
        description: "Associate clips with specific occasions for shared memories"
      },
      {
        name: "Explore & discover",
        description: "Find nearby events or search for specific ones"
      },
      {
        name: "Engage with others",
        description: "Like, comment, and share to build community"
      }
    ],
    tools: [
      "React Native",
      "AWS"
    ]
  },
  // Flex
  {
    id: "71d5ec3b-d1e0-4df9-895b-ef5de171c17b",
    name: "FLEX",
    tagLine: "Your Community Workout Companion",
    description: "FLEX is the mobile app that transforms your workout routine into a social and personalized experience. It seamlessly blends fitness with social interaction, empowering you to achieve your goals with the support of a like-minded community",
    image: {
      src: flex,
      alt: "FLEX",
      className: 'object-center object-cover'
    },
    url: "",
    features: [
      {
        name: "Discover customized workouts",
        description: "Find personalized routines tailored to your fitness level and goals"
      },
      {
        name: "Share your journey",
        description: "Track your progress, post workout updates, and motivate others"
      },
      {
        name: "Connect with the community",
        description: "Engage with fellow fitness enthusiasts, find workout buddies, and share experiences"
      },
      {
        name: "Elevate your motivation",
        description: "Stay inspired with challenges, social support, and a fun, interactive environment"
      }
    ],
    screen: [
      Flex1,
      Flex2
    ],
    tools: ["React Native", "Nodejs", "Firebase"]
  },
  // BluKyte
  {
    id: "8d1b31f5-58a1-41ac-90cd-8cf3ffdc2ba8",
    name: "BluKyte",
    tagLine: "All-in-One Trip Companion",
    description: "BluKyte is the ultimate travel planning app that empowers you to seamlessly manage every aspect of your trip, from expenses and experiences to ideas and tasks. It's your one-stop shop for creating a stress-free and unforgettable travel experience",
    image: {
      src: blukyte,
      alt: 'BluKyte',
      className: 'object-center object-contain'
    },
    url: "",
    screen: [blukyte1, blukyte2, blukyte3],
    features: [
      {
        name: "Track expenses",
        description: "Effortlessly record and categorize your trip costs, keeping your budget on track"
      },
      {
        name: "Capture experiences",
        description: "Document your adventures with photos, notes, and memories, creating a personalized travel journal"
      },
      {
        name: "Spark inspiration",
        description: "Organize and brainstorm trip ideas, ensuring you don't miss out on hidden gems"
      },
      {
        name: "Manage tasks",
        description: "Create and check off essential tasks, ensuring a smooth and organized travel experience"
      }
    ],
    tools: [
      "React Native",
      "Nodejs",
      "Firebase"
    ],
    className: 'py-4'
  },
  // Klimb
  {
    id: "35bc78f1-4f4e-44f1-921f-0df6be21d2e1",
    name: "Klimb",
    tagLine: "Conquer Trails Together, One Climb at a Time",
    description: "KLIMB is the mobile app that transforms trail climbing into a social and competitive experience. It connects you with a community of passionate climbers, allowing you to challenge yourself, track your progress, and compete for glory on the leaderboard",
    image: {
      src: klimb,
      alt: 'Klimb',
      className: 'object-center object-cover'
    },
    url: "",
    screen: [],
    features: [
      {
        name: "Climb solo or duo",
        description: "Choose between single or double climbs for personalized challenges"
      },
      {
        name: "Track your time",
        description: "Record your ascent and descent times, pushing your limits and improving your performance"
      },
      {
        name: "Compete with the community",
        description: "Climb the leaderboard and see how you stack up against other climbers"
      },
      {
        name: "Connect with fellow climbers",
        description: "Share experiences, motivate each other, and build a supportive community"
      }
    ],
    tools: [
      "React Native",
      "Google Maps",
      "Firebase"
    ]
  },
  // MS Books
  {
    id: "81fb84f7-18d5-4d9b-a4a0-9b9d7e4317b5",
    name: "MS Books",
    tagLine: "Achieve A*-level success with trusted resources",
    description: "MS Books is your trusted online resource for achieving academic excellence in O Level, IGCSE, and A Level examinations. We empower students with comprehensive study materials, innovative tools, and expert guidance to help them unlock their full potential and consistently achieve top grades (A*)",
    image: {
      src: MSBookMobile,
      alt: "MS Books",
      className: 'object-top object-cover'
    },
    url: "",
    features: [
      {
        name: "In-depth Study Guides",
        description: "Gain a clear and thorough understanding of key concepts with our high-quality study guides, crafted by experienced educators"
      },
      {
        name: "Effective Learning Tools",
        description: "Utilize a variety of interactive resources, including practice questions, mock exams, and flashcards, to solidify your knowledge and refine your exam technique"
      },
      {
        name: "Expert Guidance",
        description: "Access valuable tips, strategies, and support from our team of dedicated education professionals"
      },
      {
        name: "Proven Track Record",
        description: "Leverage our proven methods and materials, trusted by countless students who have achieved outstanding results"
      }
    ],
    screen: [],
    tools: ["React Native"]
  },
  // MasmasFit
  {
    id: "20b6e4ae-cc5b-48ad-9dc2-2f23e837b1d8",
    name: "MasmasFit",
    tagLine: "Fitness Made Simple for Busy Lads",
    description: "MasmasFit is the fitness app designed for busy guys like you. We understand the challenges of juggling work, social life, and staying fit. That's why we created a simple and effective platform to help you achieve your fitness goals, even with a packed schedule",
    image: {
      src: masmasFit,
      alt: "MasmasFit",
      className: 'object-center object-contain'
    },
    url: "",
    features: [
      {
        name: "Fit fitness into your life",
        description: "Our quick and effective workouts require minimal time commitment, perfect for busy schedules"
      },
      {
        name: "Track your progress",
        description: "Monitor your daily workout performance and meal plans with easy-to-understand graphical representations"
      },
      {
        name: "Stay motivated",
        description: "Compare your progress over time and celebrate your milestones"
      },
      {
        name: "Enjoy a user-friendly experience",
        description: "Our intuitive interface makes MasmasFit easy to use, regardless of your technical expertise"
      }
    ],
    screen: [
      Mesmesfit1,
      Mesmesfit2,
    ],
    tools: ["React Native"]
  },
  // Hope Up
  {
    id: "4505fb47-2eac-4c47-a82c-b3a69bb10145",
    name: "Hope Up",
    tagLine: "Social Hub for Inspiration and Empowerment",
    description: "Hope Up is more than just a platform; it's a community built on inspiration, motivation, and uplifting support. We connect individuals and businesses to empower collective growth and positive change",
    image: {
      src: hopeUp,
      alt: "Hope Up",
      className: 'object-top object-cover'
    },
    url: "",
    features: [
      {
        name: "Foster community",
        description: "Create a supportive and inclusive space where members can connect, motivate, and uplift each other"
      },
      {
        name: "Empower black-owned businesses",
        description: "Provide a platform for black-owned businesses, professionals, and entrepreneurs to thrive and gain visibility"
      },
      {
        name: "Celebrate diversity and inclusion",
        description: "Promote diverse perspectives and experiences to build a more just and equitable world"
      }
    ],
    screen: [],
    tools: ["React Native"]
  },
  // Temple Of Fit
  {
    id: "d64f3542-14a3-4c8e-b22d-ecdc434bb2d3",
    name: "Temple Of Fit",
    tagLine: "All-in-One Wellness Tracker",
    description: "Temple Of Fit is your comprehensive mobile companion for achieving your health and fitness goals. It goes beyond simply counting steps, offering a holistic approach to tracking and understanding your well-being",
    image: {
      src: templeoffit,
      alt: "Temple Of Fit",
      className: 'object-center object-contain'
    },
    url: "",
    screen: [
      TempleOfFit2,
      TempleOfFit4,
    ],
    features: [
      {
        name: "Multi-Device Compatibility",
        description: "Seamlessly connect with various popular smartwatches like Fitbit, Withings, Apple Watch, and Garmin"
      },
      {
        name: "User-friendly Interface",
        description: "Enjoy a simple and intuitive design accessible to users of all technical backgrounds"
      },
      {
        name: "Comprehensive Tracking",
        description: "Monitor key metrics like steps taken, calories burned, distance covered, sleep duration, and heart rate (with compatible watches)"
      }
    ],
    tools: ["React Native"],
    className: 'px-3',
  },
  // Recipe Cup - User
  {
    id: "7e4d08d3-9db1-4934-89c1-8a88840fbc7c",
    name: "Recipe Cup - User",
    tagLine: "One-Stop Shop for Delicious Discoveries",
    description: "Recipe Cup User is your convenient and easy-to-use mobile app for exploring and ordering mouthwatering dishes from a wide range of restaurants near you",
    image: {
      src: recipeCup,
      alt: "Recipe Cup - User",
      className: 'object-center object-contain'
    },
    url: "",
    screen: [
      Rescipe1,
      Rescipe2,
      Rescipe3,
      Rescipe4,
      Rescipe5,
    ],
    features: [
      {
        name: "Extensive Restaurant Selection",
        description: "Discover a diverse variety of restaurants catering to different cuisines and preferences"
      },
      {
        name: "Intuitive Interface",
        description: "Navigate the app with ease, even if you're not tech-savvy, and find what you're craving effortlessly"
      },
      {
        name: "Seamless Ordering",
        description: "Place your order with just a few taps, adding multiple items from your chosen restaurant"
      },
      {
        name: "Flexible Payment Options",
        description: "Choose your preferred payment method, whether it's adding a credit card to your account or opting for cash on delivery"
      },
      {
        name: "Delivery Convenience",
        description: "Select your desired delivery location and enjoy your meal delivered fresh to your doorstep"
      }
    ],
    tools: ["React Native"]
  },
  // Recipe Cup - Business
  {
    id: "3c5d38fc-2c7b-42c1-93bc-4b9c7155b58f",
    name: "Recipe Cup - Business",
    tagLine: "One-Stop Shop for Delicious Discoveries",
    description: "Recipe Cup User is your convenient and easy-to-use mobile app for exploring and ordering mouthwatering dishes from a wide range of restaurants near you",
    image: {
      src: recipeCup,
      alt: "Recipe Cup - Business",
      className: 'object-center object-contain'
    },
    url: "",
    screen: [
      Rescipe4,
      Rescipe5,
    ],
    features: [
      {
        name: "Extensive Restaurant Selection",
        description: "Discover a diverse variety of restaurants catering to different cuisines and preferences"
      },
      {
        name: "Intuitive Interface",
        description: "Navigate the app with ease, even if you're not tech-savvy, and find what you're craving effortlessly"
      },
      {
        name: "Seamless Ordering",
        description: "Place your order with just a few taps, adding multiple items from your chosen restaurant"
      },
      {
        name: "Flexible Payment Options",
        description: "Choose your preferred payment method, whether it's adding a credit card to your account or opting for cash on delivery"
      },
      {
        name: "Delivery Convenience",
        description: "Select your desired delivery location and enjoy your meal delivered fresh to your doorstep"
      }
    ],
    tools: ["React Native"]
  },
  // Tactiver
  {
    id: "89a62fb0-4cb2-41b5-bc44-34b5896b4808",
    name: "Tactiver",
    tagLine: "Custom Clothing Made Easy",
    description: "Tactiver empowers you to design and order custom-made clothes that perfectly fit your style and measurements",
    image: {
      src: tactiver,
      alt: "Tactiver",
      className: 'object-center object-cover'
    },
    url: "",
    features: [
      {
        name: "Extensive Design Library",
        description: "Browse through a diverse collection of stylish cloth designs to find the perfect match for your taste"
      },
      {
        name: "User-Friendly Interface",
        description: "Enjoy a simple and intuitive experience, even if you're not familiar with design software"
      },
      {
        name: "Precise Customization",
        description: "Enter your exact measurements (shoulder, height, arm length, neck, etc.) for a perfect fit"
      },
      {
        name: "Hassle-free Ordering",
        description: "Select your desired design, enter your measurements, and place your order with ease"
      },
      {
        name: "Favorite Designs",
        description: "Save designs you love for future reference or easy reordering"
      },
      {
        name: "Secure Payment",
        description: "Pay securely using your credit card"
      }
    ],
    screen: [
      Tactiver1,
      Tactiver2
    ],
    tools: ["React Native"],
  },
  // Tu Ayuda
  {
    id: "8272b9ec-1c4a-499d-9209-c08e300d66ae",
    name: "Tu Ayuda",
    tagLine: "All-in-One App for Getting Things Done",
    description: "Tu Ayuda is your one-stop solution for simplifying your life with convenient and reliable home and personal services. We connect you with experienced professionals to handle various tasks, from cleaning your home to tutoring your children",
    image: {
      src: tuAyuda,
      alt: "Tu Ayuda",
      className: 'object-center object-cover'
    },
    url: "",
    features: [
      {
        name: "Wide Range of Services",
        description: "Access a diverse selection of services, including cleaning, plumbing, repairs, carpet cleaning, electrical work, and more",
      },
      {
        name: "Effortless Booking",
        description: "Use our user-friendly mobile app to book services with just a few taps, specifying the desired date, time, and location"
      },
      {
        name: "Seamless Payment",
        description: "Pay for services securely through our online payment system using your credit card"
      },
      {
        name: "Approval Process",
        description: "Ensure your booking is fulfilled by qualified professionals through our approval system"
      },
      {
        name: "Review and Feedback",
        description: "Provide feedback on your service experience to help us maintain high standards"
      }
    ],
    screen: [
      Tuayada1,
      Tuayada2,
    ],
    tools: ["React Native"]
  },
  // Meloswip
  {
    id: "22d5f7c8-3810-4e47-81d8-22992b59a83a",
    name: "Meloswip",
    tagLine: "Connecting Artists and Producers Seamlessly",
    description: "Meloswip is a revolutionary platform designed to bridge the gap between artists and music producers",
    image: null,
    url: "",
    screen: [],
    features: [
      {
        name: "Streamlined Beat Discovery",
        description: "Swipe through a curated selection of beats like Tinder"
      },
      {
        name: "Full Beat Experience",
        description: "Seamlessly access full beats (instead of previews) through a dedicated player interface"
      },
      {
        name: "Genre Filters",
        description: "Refine your search by filtering beats based on desired genres (Hip-Hop, R&B, etc.)"
      },
      {
        name: "On-Demand Beat Requests",
        description: "Post Gigs: Specify your needs (e.g., 'Drake house type beat,' tempo range, genres, and budget)"
      },
      {
        name: "Review and Choose",
        description: "Explore offers through the player interface and select the perfect beat for your project"
      }
    ],
    tools: [
      "Mobile app (Android, iOS)",
      "Swipe interface",
      "Genre and hashtag filtering",
      "Secure payment gateway",
      "In-app chat (for potential future implementation)"
    ]
  },
  // Street Food
  {
    id: "e4dd3838-28f5-4e29-a49f-48a27848b4dc",
    name: "Street food",
    tagLine: '',
    description: 'Street food app is provide the food. user can select the any food items and place the "order" then vender prepared the "order".',
    image: {
      src: streetFood,
      alt: "Street food",
      className: 'object-center object-contain'
    },
    url: 'streetFood',
    features: [],
    screen: [
      Street1,
      Street2
    ],
    tools: ["React Native"],
    className: 'px-3 ob',
  },
  // Image Sticker
  {
    id: "4bda34cf-0b81-464b-a22d-88b66d95a01e",
    name: "Image Sticker",
    tagLine: '',
    description: 'Image stacker app to create emoji with select multiple images.',
    image: {
      src: imageSticker,
      alt: "Image Sticker",
      className: 'object-center object-contain'
    },
    url: "",
    features: [],
    screen: [
      Imagesticker1,
      Imagesticker1,
    ],
    tools: ["React Native"],
  },
  // Zetra Automation
  {
    id: "f1d5e11a-9a09-4e0b-ba0a-fc1f65a0c064",
    name: "Zetra Automation",
    tagLine: '',
    description: 'Zetra Automation app to provide the build file of client, the client test the app and if any issue in app then client submit feedback.',
    image: {
      src: zetraAutomation,
      alt: "Zetra Automation",
      className: 'object-center object-contain'
    },
    url: "",
    features: [
      {
        name: 'Services',
        description: 'Zetra automation to provide build file.'
      },
      {
        name: 'Easy to use',
        description: 'User friendly interface, every user can use easly'
      },
      {
        name: 'Build assistance',
        description: 'Client download the build file and test it if any issue in app then client submit feedback.'
      },
    ],
    screen: [
      Zetra1,
      Zetra2
    ],
    tools: ["React Native"],
    className: 'px-3'
  },
  // Yest
  {
    id: "027a13b4-1012-4a0c-8502-9d0bcb69f9e8",
    name: "Yest",
    tagLine: '',
    description: 'app',
    image: {
      src: Yest,
      alt: "Yest",
      className: 'object-center object-contain'
    },
    url: "",
    tools: ["React Native"],
    screen: [
      Yest1,
      Yest2
    ],
    features: []
  },
  // Iviou
  {
    id: "e1e52d5d-f9c0-4f0e-83e5-0a77a6ef23f4",
    name: "Iviou",
    tagLine: '',
    description: 'app',
    image: {
      src: Iviou,
      alt: "Iviou",
      className: 'object-center object-contain'
    },
    url: "",
    tools: ["React Native"],
    screen: [
      lviou1,
      lviou2
    ],
    features: []
  },
  // My Pet Buddy
  {
    id: "3a72d33a-6a44-4a11-b42b-8dc2c5de6db1",
    name: "My pet buddy",
    tagLine: '',
    description: 'app',
    image: {
      src: myPetBuddy,
      alt: "My pet buddy",
      className: 'object-center object-contain'
    },
    url: "",
    features: [],
    screen: [
      MyPetBuddy1,
      MyPetBuddy2

    ],
    tools: ["React Native"],
    className: 'px-3'
  },
  // Film Stack
  {
    id: "d20a533a-5a0a-437e-a1d8-7da5a9d10a32",
    name: "Film stack",
    tagLine: '',
    description: "",
    image: {
      src: filmStack,
      alt: "Film stack",
      className: 'object-center object-contain'
    },
    url: "",
    features: [],
    screen: [
      Film1,
      Film2,
    ],
    tools: ["React Native"],
  },
  // Veri me
  {
    id: "63d4c5b9-92e0-4a25-9618-d5bb46edf157",
    name: "Veri me",
    tagLine: '',
    description: 'The simple way to manage your documents. VeriMe are providing a secure platform for businessess and consumers to connect.',
    image: {
      src: veriMe,
      alt: "Veri me",
      className: 'object-center object-cover'
    },
    url: "",
    features: [
      {
        name: 'Scan Documents',
        description: 'Designing a good website that accommodates a lot of content is a tricky balancing act to pull off.'
      },
      {
        name: 'Easy to use',
        description: 'We build mobile apps for the conference, integrating unique content and branding to create.'
      },
      {
        name: 'Police Verification',
        description: 'Google has made this important since 1998 when it launched. Content became, and still is king since websites.'
      },
      {
        name: 'Sign Documents',
        description: 'We build mobile apps for the conference, integrating unique content and branding to create.'
      },
      {
        name: 'Phone Number Verification',
        description: 'Google has made this important since 1998 when it launched. Content became, and still is king since websites'
      },
      {
        name: 'Form Questions',
        description: 'Google has made this important since 1998 when it launched. Content became, and still is king since websites.'
      },
    ],
    screen: [
      VeriMe1,
      VeriMe2
    ],
    tools: ["React Native"],
  },
  // Lega Chat
  {
    id: "b4f83c7f-6477-40fb-a23c-62ab90ef73b7",
    name: "Lega Chat",
    tagLine: '',
    description: "",
    image: {
      src: legaChat,
      alt: "Lega Chat",
      className: 'object-center object-cover'
    },
    url: "",
    features: [],
    tools: ["React Native"],
  },
  // Get safe now
  {
    id: "2b4e0d07-f1e5-4eb4-9f44-df246a9d2ae3",
    name: "Get safe now",
    tagLine: '',
    description: "",
    image: {
      src: getSafeNow,
      alt: "Get safe now",
      className: 'object-center object-contain'
    },
    url: "",
    features: [],
    tools: ["React Native"],
  },
]

export const services: Service[] = [
  {
    title: 'Web Development',
    description: 'I use proven web technologies to ensure that you receive an efficient fast-loading-website every single time and cater to the needs of your target market',
    image: {
      bgColor: '',
      src: webDevelopment,
      alt: 'Web Development',
    },
  },
  {
    title: 'App Development',
    description: 'I take care of different mobile phone sizes, variants and screen resolutions while developing mobile apps',
    image: {
      bgColor: '#fff',
      src: appDevelopment,
      alt: 'App Development',
    },
  },
  {
    title: 'UI/UX Design',
    description: 'I plan your site as indicated by your own request, indeed, your business to accomplish best results for you',
    image: {
      bgColor: '',
      src: uiuxDesign,
      alt: 'UI/UX Design',
    },
  },
]

export const reviews = [
  {
    id: "667dd18c-46a4-4e70-b47e-5cb6a9ebf5b4",
    name: "Virat Kohli",
    profession: "Cricketer",
    image: Picture1,
    date: 'Sep 2009',
    star: '5',
    title: 'Great Quality!',
    comment: 'In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.'
  },
  {
    id: "49f26920-5802-4784-9b7e-c1847f36427c",
    name: "Babar Azam",
    profession: "Cricketer",
    image: Picture2,
    date: 'Sep 2011',
    star: '4',
    title: 'Great Quality!',
    comment: 'In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.'
  },
  {
    id: "99936284-36d7-4f92-95cf-73833b37778c",
    name: "Kane Williamson",
    profession: "Graphic Designer",
    image: Picture3,
    date: 'Sep 2006',
    star: '3.9',
    title: 'Great Quality!',
    comment: 'In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.'
  },
  {
    id: "f5fe5548-02c8-45b6-97a3-22798a987570",
    name: "Imam-ul-Haq",
    profession: "Graphic Designer",
    image: Picture4,
    date: 'Jun 2022',
    star: '2',
    title: 'Great Quality!',
    comment: 'In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.'
  },
  {
    id: "97fb5f6d-6eb9-497a-b450-bbc67b0a65fd",
    name: "James Gouse",
    profession: "Graphic Designer",
    image: Picture5,
    date: 'Dec 2016',
    star: '1',
    title: 'Great Quality!',
    comment: 'In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.'
  },
  {
    id: "7f2a5984-b376-48ed-91b1-f524b6e27da5",
    name: "James Gouse",
    profession: "Graphic Designer",
    image: Picture6,
    date: 'Nov 2016',
    star: '4',
    title: 'Great Quality!',
    comment: 'In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.'
  },
  {
    id: "c0d8e1cb-1a34-4d35-8dfb-363536812ac6",
    name: "James Gouse",
    profession: "Graphic Designer",
    image: Picture7,
    date: 'Sep 2016',
    star: '3',
    title: 'Great Quality!',
    comment: 'In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.'
  },
  {
    id: "3b4df1a7-02c1-490f-bbf0-d7e98bf3f4cd",
    name: "James Gouse",
    profession: "Graphic Designer",
    image: Picture8,
    date: 'Sep 2016',
    star: '4.6',
    title: 'Great Quality!',
    comment: 'In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.'
  },
  {
    id: "e55e9c25-21f0-493e-9f7c-448ee19186f4",
    name: "James Gouse",
    profession: "Graphic Designer",
    image: Picture9,
    date: 'Sep 2016',
    star: '3',
    title: 'Great Quality!',
    comment: 'In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.'
  },
  {
    id: "98a09a4e-3d40-469e-b2ac-0d05151032d8",
    name: "James Gouse",
    profession: "Graphic Designer",
    image: Picture10,
    date: 'Sep 2017',
    star: '4.7',
    title: 'Great Quality!',
    comment: 'In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.'
  }
]


export const industries: Industries[] = [
  {
    title: 'Health care',
    description: 'Bringing healthcares services to next level',
    industryDetail: 'The healthcare industry (also called the medical industry or health economy) is an aggregation and integration of sectors within the economic system that provides goods and services to treat patients with curative, preventive, rehabilitative, and palliative care. It includes the generation and commercialization of goods and services lending themselves to maintaining and re-establishing health.',
    route: 'health-care',
    image: {
      src: Healthcare,
      alt: 'health care',
    },
    website: [
      {
        name: "masmasfit",
        image: masmasFit,
        url: "",
        description: 'MasmasFit is a fitness app with a goal to engage more lads who are working people from morning to evening and barely get time to do some workout due to extensive work pressure and busy with social life. This app will encourage them to keep their body fit and lead a healthy life through using it. Users can track his performance on a daily basis using the app. Users can also check their previous exercises records.',
        screen: [
          Mesmesfit1,
          Mesmesfit2,
        ],
        features: [
          'React native'
        ],
        order: true
      },
    ]
  },
  {
    title: 'Fitness',
    description: 'Bringing fitness services to next level',
    industryDetail: 'Fitness Hacks for Life was created to help those dealing with symptoms of post-traumatic stress disorder, such as anxiety, depression, hypersensitivity, and avoidance behaviors. While these responses stem from trauma and pain, without help, they can spread throughout all the avenues of our lives. ',
    route: 'fitness',
    image: {
      src: Fitness,
      alt: 'fitness',
    },
    website: [
      {
        name: "masmasfit",
        image: masmasFit,
        url: "",
        description: 'MasmasFit is a fitness app with a goal to engage more lads who are working people from morning to evening and barely get time to do some workout due to extensive work pressure and busy with social life. This app will encourage them to keep their body fit and lead a healthy life through using it. Users can track his performance on a daily basis using the app. Users can also check their previous exercises records.',
        screen: [
          Mesmesfit1,
          Mesmesfit2,
        ],
        features: [
          'React native'
        ],
        order: true
      },
      {
        name: "Flex",
        image: flex,
        url: "",
        description: 'Flex is a fitness app with a goal to engage more lads who are working people from morning to evening and barely get time to do some workout due to extensive work pressure and busy with social life. This app will encourage them to keep their body fit and lead a healthy life through using it.',
        screen: [
          Flex1,
          Flex2
        ],
        features: ['React native'],
        order: false
      },
      {
        name: "Temple of fit",
        image: templeoffit,
        url: "",
        description: 'Temple of fit is step counter application that help the user to conut his steps, to burn calories, calculate the distance, to calculate the sleep hours and calculate the heart beat. In this app three watchs integrated like fitbit, withings and applekit.',
        screen: [
          TempleOfFit2
        ],
        features: [
          'React Native',
        ],
        order: true
      },
    ],
  },
  {
    title: 'Nfts',
    description: 'Bringing nfts services to next level',
    industryDetail: `NFTs can represent digital or real-world items like artwork and real estate. "Tokenizing" these real-world tangible assets makes buying, selling, and trading them more efficient while reducing the probability of fraud. NFTs can represent individuals' identities, property rights, and more. `,
    route: 'nfts',
    image: {
      src: Nfts,
      alt: 'Nfts',
    },
    website: [
      {
        name: "Nerko",
        image: Nerko1,
        url: "https://nerko.demo1.zsoft.link/",
        screen: [
          Nerko1,
          Nerko2,
        ],
        features: [
          'React js', 'Bootstrap', 'Swiper'
        ],
        position: "top",
        order: true
      },
      {
        name: "Off the chain",
        image: Offthechain3,
        url: "",
        description: 'Off the chain is a website that is created to sell Nfts printed shirts. User can connect its wallet select PFP pay 0.08ETH and get a premium custom tree in the mail.',
        features: [
          'React Js', 'Bootstrap', 'NFTs', 'MetaMask', 'Node Js', 'Web 3'
        ],
        screen: [
          Offthechain1,
          Offthechain2,
          Offthechain3,
        ],
        order: false
      },
      {
        name: "Cyron",
        image: Cyron1,
        url: "https://cyron.demo1.zsoft.link/",
        screen: [
          Cyron1,
          Cyron2,
        ],
        features: [
          'React js', 'Bootstrap', 'Swiper'
        ],
        position: "top",
        order: true
      },
      {
        name: "Smeisty.App",
        image: Smeisty2,
        url: "https://smeisty.app/",
        description: 'Smeisty is a 100% digital platform that makes it easy for companies to manage their NFT communities. It supports companies at every stage of their journey–from strategy and launch to sustained management of their brands and customer communities.',
        features: ['React Js', 'Redux', 'Redux toolkit query', 'Node Js', 'Bootstrap', 'MetaMask'],
        screen: [
          Smeisty1,
          Smeisty2,
          Smeisty4,
          Smeisty5,
        ],
        position: "top",
        order: false
      },
    ]
  },
  {
    title: 'Block chain',
    description: 'Bringing block chain services to next level',
    industryDetail: `Block chain technology is a decentralized, distributed ledger that stores the record of ownership of digital assets. Any data stored on block chain is unable to be modified, making the technology a legitimate disruptor for industries like payments, cybersecurity and healthcare. `,
    route: 'block-chain',
    image: {
      src: Blockchain,
      alt: 'Blockchain',
    },
    website: [
      {
        name: "Nerko",
        image: Nerko1,
        url: "https://nerko.demo1.zsoft.link/",
        screen: [
          Nerko1,
          Nerko2,
        ],
        features: [
          'React js', 'Bootstrap', 'Swiper'
        ],
        position: "top",
        order: true
      },
      {
        name: "Off the chain",
        image: Offthechain3,
        url: "",
        description: 'Off the chain is a website that is created to sell Nfts printed shirts. User can connect its wallet select PFP pay 0.08ETH and get a premium custom tree in the mail.',
        features: [
          'React Js', 'Bootstrap', 'NFTs', 'MetaMask', 'Node Js', 'Web 3'
        ],
        screen: [
          Offthechain1,
          Offthechain2,
          Offthechain3,
        ],
        order: false
      },
      {
        name: "Cyron",
        image: Cyron1,
        url: "https://cyron.demo1.zsoft.link/",
        screen: [
          Cyron1,
          Cyron2,
        ],
        features: [
          'React js', 'Bootstrap', 'Swiper'
        ],
        position: "top",
        order: true
      },
      {
        name: "Smeisty.App",
        image: Smeisty2,
        url: "https://smeisty.app/",
        description: 'Smeisty is a 100% digital platform that makes it easy for companies to manage their NFT communities. It supports companies at every stage of their journey–from strategy and launch to sustained management of their brands and customer communities.',
        features: ['React Js', 'Redux', 'Redux toolkit query', 'Node Js', 'Bootstrap', 'MetaMask'],
        screen: [
          Smeisty1,
          Smeisty2,
          Smeisty4,
          Smeisty5,
        ],
        position: "top",
        order: false
      },
    ]
  },
  {
    title: 'E commerce',
    description: 'Bringing e commerce services to next level',
    industryDetail: `The exchange of products and services through the Internet is known as e-commerce. It includes a wide range of information, programmers, and resources for online buyers and sellers, such as mobile shopping and encryption for online payments. `,
    route: 'e-commerce',
    image: {
      src: Ecom,
      alt: 'e com',
    },
    website: [
      {
        name: "Cartisan web",
        image: cartisan,
        url: "https://cartisan.demo.zsoft.link/",
        features: ['Next Js', 'React Redux', 'Redux toolkit', 'Bootstarp', 'Firebase', 'Algolia', 'Swiper'],
        screen: [
          Cartisan1,
          Cartisan2,
          Cartisan3,
        ],
        position: "top",
        order: true
      },
    ]
  },
  {
    title: 'Travel',
    description: 'Bringing travel services to next level',
    industryDetail: `The tourism industry, also known as the travel industry, is linked to the idea of people travelling to other locations, either domestically or internationally, for leisure, social or business purposes. `,
    route: 'travel',
    image: {
      src: Travel,
      alt: 'travel',
    },
    website: [
      {
        name: "Al jazeera travel",
        image: aljazeeraTravels,
        url: "https://www.aljazeratravel.com/",
        features: ['React Js', 'PHP'],
        position: "top",
        order: true
      },
      {
        name: "TRAVELOSTIC",
        image: travelostic,
        url: "https://travelostic.com/",
        features: ['React Js', 'PHP'],
        position: "top",
        order: false
      },
    ]
  },
]