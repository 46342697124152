import React, { FC } from 'react';
import { Route, Routes } from "react-router-dom";
import IndustriesDetail from '../pages/IndustriesDetail';

import Main from "../pages/Main";
import Meeting from "../pages/Meeting";
import ProjectDetail from '../pages/ProjectDetail';
import Testing from '../pages/Testing';
import Portfolio from '../pages/Portfolio';

type Props = {
  handleBackClick(className: string): void
}

const Routing: FC<Props> = ({handleBackClick}) => {

  return (
    <Routes>
      <Route index path='/' element={<Main handleBackClick={handleBackClick}/>}></Route>
      <Route index path='/testing' element={<Testing />}></Route>
      <Route path='/meeting' element={<Meeting />}></Route>
      <Route path='/industries/:name' element={<IndustriesDetail />}></Route>
      <Route path='/portfolio' element={<Portfolio/>}></Route>
      <Route path='/projects/:id' element={<ProjectDetail/>}></Route>
    </Routes>
  )
}

export default Routing